export const GenericAccounts = {
    CENIK: "cenik@ldseating.com",
    CENYSK: "cenysk@ldseating.com",
    PREISLISTE: "preisliste@ldseating.com",
    LDPRICELIST: "ldpricelist@ldseating.com",
    FRLISTEDEPRIX: "frlistedeprix@ldseating.com",
    ATPREISLISTE: "atpreisliste@ldseating.com",
    NLPRICELIST: "nlpricelist@ldseating.com",
    CHPREISLISTE: "chpreisliste@ldseating.com",
    UNITED: "united@ldseating.com",
    ISLAND: "island@ldseating.com",
    PORTLAND: "portland@ldseating.com",
}