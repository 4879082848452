<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="container transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all"
              :class="props.panelClasses"
            >
              <button
                v-if="!props.preventClose"
                class="absolute right-8 top-8"
                @click="$emit('close')"
              >
                <Icon name="fa6-solid:xmark" size="32" />
              </button>
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/vue'

const props = defineProps<{
  isOpen: boolean
  panelClasses?: string
  preventClose?: boolean
}>()

const emit = defineEmits(['close'])

function closeModal() {
  if (props.preventClose) {
    return
  }
  emit('close')
}
</script>
