<template>
  <div>
    <UPopover
      :popper="{ offsetDistance: 16 }"
      mode="click"
      :ui="{
        background: 'bg-black p-5 text-white shadow',
        rounded: 'rounded-2xl',
      }"
    >
      <UiModal
        :is-open="showDisabledAccountModal"
        @close="closeDisabledAccountModal()"
        panel-classes="max-w-3xl"
      >
        <div class="mx-auto flex flex-col gap-4">
          <p class="text-pragmatica-extended text-xl font-bold">
            {{ t('login.disabledAccount', [currentDisabledAccount]) }}
          </p>
          <div class="ml-auto flex gap-2">
            <button
              class="w-fit rounded-full px-3 py-1 text-lg outline"
              @click="closeDisabledAccountModal()"
            >
              {{ t('login.differentAccount') }}
            </button>
            <button
              class="w-fit rounded-full bg-black px-3 py-1 text-lg text-white outline"
              @click="rerouteToRegister()"
            >
              <NuxtLink class="button dark !px-3" :to="$t('links.registerUrl')">
                {{ t('links.register') }}
              </NuxtLink>
            </button>
          </div>
        </div>
      </UiModal>
      <button
        class="flex w-full items-center justify-center rounded-full bg-black px-4 py-2 text-white"
      >
        <div v-if="isLoggedIn" class="flex items-center gap-2">
          <Icon name="fa6-solid:user" class="mb-1" />
          <p>{{ user?.firstName }} {{ user?.lastName }}</p>
        </div>
        <div v-else class="flex items-center gap-2">
          <p>
            {{ $t('login.login') }}
          </p>
          <Icon name="fa6-solid:chevron-down" class="mb-1" />
        </div>
      </button>

      <template #panel>
        <div>
          <form v-if="!isLoggedIn" @submit.prevent="login">
            <div>
              <div v-if="!loading" class="flex flex-col gap-4">
                <input
                  v-model="email"
                  type="text"
                  autocomplete="email"
                  class="w-96 rounded-full px-4 py-2 text-black"
                  :placeholder="$t('login.email')"
                />
                <input
                  v-model="password"
                  type="password"
                  autocomplete="current-password"
                  class="w-96 rounded-full px-4 py-2 text-black"
                  :placeholder="$t('login.password')"
                />
                <div v-if="showError" class="rounded-full bg-red-500 px-4 py-2">
                  {{ $t('login.error') }}
                </div>
                <button
                  class="text-pragmatica-extended w-full rounded-full bg-white px-4 py-2 font-bold text-black"
                >
                  {{ $t('login.login') }}
                </button>
              </div>
              <div v-else class="px-40 py-24">
                <p>{{ $t('login.loading') }}</p>
              </div>
            </div>
          </form>
          <form
            v-else
            class="rounded-2xl bg-black p-5 text-white shadow"
            @submit.prevent
          >
            <div class="flex flex-col gap-2">
              <NuxtLink
                class="text-pragmatica-extended w-full whitespace-nowrap rounded-full bg-white px-4 py-2 font-bold text-black"
                :to="localePath({ name: 'orders', query: { page: '1' } })"
              >
                {{ $t('login.orders') }}
              </NuxtLink>
              <button
                class="text-pragmatica-extended w-full whitespace-nowrap rounded-full bg-white px-4 py-2 font-bold text-black"
                @click="logout"
              >
                {{ $t('login.logout') }}
              </button>
            </div>
          </form>
        </div>
      </template>
    </UPopover>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useUser } from '~/stores/user'
import { GenericAccounts } from '~/enums/genericAccounts'

const userStore = useUser()
const api = useApi()
const localePath = useLocalePath()

await userStore.init()

const { user, isLoggedIn } = storeToRefs(userStore)
const { t } = useI18n()

const email = ref('')
const password = ref('')
const loading = ref(false)
const showError = ref(false)
const showDisabledAccountModal = ref(false)
const currentDisabledAccount = ref('')

async function logout() {
  await api.logout()
}

async function login() {
  loading.value = true
  showError.value = false

  if (Object.values(GenericAccounts).includes(email.value)) {
    currentDisabledAccount.value = email.value
    openDisabledAccountModal()
  } else {
    await api
      .login(email.value, password.value)
      .catch(() => (showError.value = true))
  }

  password.value = ''
  loading.value = false
}

function openDisabledAccountModal() {
  showDisabledAccountModal.value = true
}

function closeDisabledAccountModal() {
  showDisabledAccountModal.value = false
}
</script>
